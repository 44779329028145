.timeoutPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -0;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.918);
    z-index: 999;
    visibility: visible;
    opacity: 1;
    transition: opacity .1s linear, visibility .1s linear, background .1s linear
}

.timeoutPopup_hidden {
    visibility: hidden;
    opacity: 0;
    background: transparent
}

.timeoutPopup_hidden .timeoutPopup__wrapper {
    top: -100%;
    visibility: hidden;
    opacity: 0
}

.timeoutPopup__wrapper {
    margin: 30px 0;
    position: relative;
    max-width: 1200px;
    visibility: visible;
    opacity: 1;
    transition: opacity .4s linear, visibility .4s linear, top .4s linear
}

.timeoutPopup__content {
    background: #2d2d2d;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.timeoutPopup iframe {
    max-width: 100%;
    width:1920px; 
    height: 580px;
}

.timeoutPopup__close {
    position: absolute;
    right: -21px;
    top: -21px;
    width: 42px;
    height: 42px;
    background: url(../img/timeoutPopup__close.png) no-repeat
}
